<template>
  <div>
    <div class="mb-4 flex flex-col">
      <span class="block font-weight-bold text-h6">{{ title }}</span>

      <div class="mt-2 flex space-x-2 flex-wrap">
        <v-btn
          v-for="tab in ARRAY_LIST_MODEL_SITES"
          :key="tab.id"
          :color="currentTab === tab.id ? 'primary' : ''"
          depressed
          small
          @click="currentTab = tab.id"
          >{{ tab.text }}
        </v-btn>
      </div>
    </div>

    <div>
      <form-seo-data-item
        v-model="internalValue[currentTab].defaultSeo"
      ></form-seo-data-item>
    </div>

    <div :key="currentTab">
      <v-checkbox
        v-model="internalValue[currentTab].isSchedule"
        class="font-weight-bold"
        label="Hẹn giờ"
      ></v-checkbox>

      <div v-if="internalValue[currentTab].isSchedule">
        <form-seo-data-item
          v-model="internalValue[currentTab].scheduleSeo"
          schedule
        ></form-seo-data-item>
      </div>
    </div>
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES } from "@/core/constant";
import { serialize, deserialize } from "./utils";
import FormSeoDataItem from "./FormSeoDataItem.vue";

export default {
  components: { FormSeoDataItem },

  props: {
    disabled: { type: Number, default: 1 },
    title: { type: String, default: "Thông tin SEO" },
    value: { type: Array, required: true }
  },
  data() {
    return {
      ARRAY_LIST_MODEL_SITES,
      currentTab: "THINKPRO",
      internalValue: serialize(this.value, this.currentTab)
    };
  },
  watch: {
    internalValue: {
      handler(value) {
        this.$emit("input", deserialize(value));
      },
      deep: true
    }
  }
};
</script>
