import {
  cloneDeep,
  forEach,
  groupBy,
  isDefined,
  isUndefined,
  map
} from "@vt7/utils";

export const serialize = (seoData, currentTab) => {
  const DEFAULT_SCHEDULE_SEO = {
    title: "",
    site: currentTab,
    description: "",
    tags: [],
    start_time: "",
    end_time: ""
  };

  const group = groupBy(cloneDeep(seoData), item => item.site);

  return map(group, items => {
    const defaultSeo = items.find(
      item => isUndefined(item.start_time) && isUndefined(item.end_time)
    );

    const scheduleSeo = items.find(
      item => isDefined(item.start_time) || isDefined(item.end_time)
    );

    return {
      defaultSeo,
      scheduleSeo: scheduleSeo || cloneDeep(DEFAULT_SCHEDULE_SEO),
      isSchedule: Boolean(scheduleSeo)
    };
  });
};

export const deserialize = seoDataValue => {
  const seoData = [];

  forEach(seoDataValue, (value, site) => {
    seoData.push(value.defaultSeo);

    if (value.isSchedule) {
      seoData.push({
        ...value.scheduleSeo,
        site
      });
    }
  });

  return seoData;
};
