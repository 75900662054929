<template>
  <v-card class="pa-5 h-full" flat>
    <div class="col-main-customize-detail">
      <div class="mb-2 font-weight-bold text-h6">Chọn sản phẩm</div>
      <button-add-product
        :defaultValue="customizeFolder.options"
        @onSelect="handleAddSkus"
      ></button-add-product>

      <form-specific-products
        v-model="customizeFolder.options"
      ></form-specific-products>
    </div>
  </v-card>
</template>

<script>
import FormSpecificProducts from "./components/FormSpecificProducts.vue";
import ButtonAddProduct from "./ButtonAddProduct/index.vue";
export default {
  components: { ButtonAddProduct, FormSpecificProducts },

  props: {
    activePanel: {
      type: Number,
      default: 0
    }
  },

  computed: {
    customizeFolder() {
      return this.$store.getters["CUSTOMIZE_FOLDER/customizeFolder"];
    },
    filtersProductTotal() {
      return this.$store.getters["CUSTOMIZE_FOLDER/filtersProductTotal"];
    }
  },

  methods: {
    handleAddSkus(value) {
      this.customizeFolder.options = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.col-main-customize-detail {
  height: 100%;
  position: relative;

  &__scrollable {
    height: calc(100% - 28px);
    overflow-y: auto;
  }

  &__sticky {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
}
</style>
