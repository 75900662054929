import { render, staticRenderFns } from "./ListTreeProduct.vue?vue&type=template&id=65883b94&scoped=true&"
import script from "./ListTreeProduct.vue?vue&type=script&lang=js&"
export * from "./ListTreeProduct.vue?vue&type=script&lang=js&"
import style0 from "./ListTreeProduct.vue?vue&type=style&index=0&id=65883b94&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65883b94",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCheckbox,VTreeview})
