<template>
  <div>
    <div>
      <div class="font-weight-medium mt-4">
        Đã chọn ({{ value ? value.length : 0 }})
      </div>

      <div class="sku-list-wrapper">
        <v-row class="px-1">
          <v-col
            v-for="(item, index) in value"
            :key="index"
            class="px-2 py-2"
            cols="12"
            lg="6"
          >
            <div
              class="card-draggable text-body-1 d-flex rounded px-3 py-2"
              draggable="true"
              style="height: 100%"
              @dragend="dragEnd"
              @dragenter="dragEnter"
              @dragleave="dragLeave"
              @dragover="dragOver"
              @dragstart="dragStart($event, item, index)"
              @drop="dragDrop($event, item, index)"
            >
              <div class="pointer-event--none mr-2">
                <v-img
                  :aspect-ratio="1"
                  :src="item.thumbnail"
                  class="grey lighten-3 rounded"
                  contain
                  height="40px"
                  width="40px"
                ></v-img>
              </div>

              <div class="pointer-event--none flex-grow-1">
                <div class="font-weight-bold">
                  {{ item.name }}
                </div>
                <div class="grey--text text--darken-2 text-subtitle-2">
                  <span class="font-weight-bold">{{ item.SKU }}</span>
                  <!--<span class="item-subtitle" v-html="item.name"></span>-->
                </div>
                <div class="font-weight-bold">
                  {{ item.price | formatCurrency }}
                </div>
              </div>

              <div>
                <v-btn icon small @click="removeSelectedProductOption(index)">
                  <v-icon color="grey lighten-1" size="20px">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/core/utils/debounce";
import { LIST_OPTION_STATUS } from "@/modules/Goods/constants";

export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      draggableItem: null,
      draggableItemIndex: null,
      productSearchKey: null
    };
  },

  computed: {
    filterSearchProductOptions() {
      if (this.value) {
        const arr = this.value.map(item => item.id);

        return this.searchProductOptions.filter(item => !arr.includes(item.id));
      } else {
        return this.searchProductOptions;
      }
    },

    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    },

    searchProductOptions() {
      return this.$store.getters["PRODUCT_OPTION/searchProductOptions"];
    }
  },

  watch: {
    productSearchKey(val) {
      if (val && val.length > 0) {
        this.debounceGetSearchProductOption();
      }
    }
  },

  created() {
    this.debounceGetSearchProductOption = debounce(
      this.getSearchProductOptions,
      300
    );
  },

  methods: {
    dragStart(event, item, index) {
      event.target.classname += " hold";

      this.draggableItem = item;
      this.draggableItemIndex = index;

      setTimeout(() => {
        event.target.className += " invisible";
      }, 0);
    },
    dragEnd(event) {
      event.target.className =
        "card-draggable text-body-1 d-flex rounded px-3 py-2";
    },

    dragOver(event) {
      event.preventDefault();
    },

    dragEnter(event) {
      event.preventDefault();

      if (event.target.className.includes("card-draggable")) {
        event.target.style.border = "1px solid #4CAF50";
      }
    },

    dragLeave(event) {
      event.target.style.border = null;
    },

    async dragDrop(event, item, index) {
      event.target.style.border = null;

      const arr = this.value;

      arr.splice(this.draggableItemIndex, 1);
      arr.splice(index, 0, this.draggableItem);

      this.$emit("input", arr);
    },

    getSearchProductOptions() {
      this.$store.dispatch("PRODUCT_OPTION/searchProductOptions", {
        search: this.productSearchKey
      });
    },

    getOptionStatus(value) {
      return LIST_OPTION_STATUS.find(item => item.id === value);
    },

    removeSelectedProductOption(index) {
      const arr = this.value;

      arr.splice(index, 1);
      this.$emit("input", arr);
    },

    selectProductOption(item) {
      if (item && item.id) {
        const arr = this.value ? this.value : [];

        arr.unshift({ ...item });
        this.$emit("input", arr);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.item-subtitle {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0 !important;
  }
}

.card-draggable {
  border: 1px solid rgba(0, 0, 0, 0.12);
  cursor: move;
}

.hold {
  opacity: 0.5;
  background: red;
}

.invisible {
  opacity: 0.5;
}

.pointer-event--none {
  pointer-events: none;
}

.sku-list-wrapper {
  height: calc(100vh - 351px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
