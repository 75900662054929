<template>
  <div class="d-flex">
    <div>
      <tp-input-time
        v-model="dateTime.time"
        dense
        hide-details
        outlined
        placeholder="Chọn giờ"
      ></tp-input-time>
    </div>

    <div class="ml-2">
      <tp-input-date
        v-model="dateTime.date"
        dense
        hide-details
        outlined
        placeholder="Chọn ngày"
      ></tp-input-date>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dateTime: this.deserialize(this.value)
    };
  },
  watch: {
    dateTime: {
      handler(value) {
        this.$emit("input", this.serialize(value));
      },
      deep: true
    }
  },
  methods: {
    deserialize(string) {
      const [date, time] = string.split(" ");

      return {
        date,
        time
      };
    },
    serialize(dateTime) {
      return `${dateTime.date} ${dateTime.time}`;
    }
  }
};
</script>
