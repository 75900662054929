<template>
  <div>
    <v-checkbox
      v-if="skusByType.length"
      v-model="isSelectedAll"
      class="checkbox-all text-caption"
      :indeterminate="isIndeterminate"
      :checked="true"
      hide-details
      @change="handleChangeSelectedAlll"
    >
      <template v-slot:label>
        <div class="checkbox-label">
          Chọn tất cả
        </div>
      </template>
    </v-checkbox>

    <v-treeview
      v-model="internalSkusId"
      selected-color="primary"
      expand-icon="mdi-chevron-down"
      selectable
      :items="skusByTypeTree"
    >
      <template v-slot:label="{ item }">
        <div v-if="!item.parent_id" style="white-space: normal">
          {{ item.name }}
        </div>

        <div v-else style="white-space: normal">
          {{ item.description }}
        </div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },

    tempSelectedSkus: {
      type: Array
    }
  },

  data() {
    return {
      isSelectedAll: false,
      skus: []
    };
  },

  computed: {
    internalSkusId: {
      get() {
        return this.value.map(item => item.id);
      },
      set(val) {
        this.$emit("input", this.getSkusById(val));
      }
    },

    isIndeterminate() {
      return (
        this.value.length > 0 &&
        this.value.length < this.skusByTypeRemovedParent.length
      );
    },

    skusByType() {
      return this.$store.getters["CUSTOMIZE_FOLDER/skusByType"];
    },

    skusByTypeRemovedParent() {
      return this.skusByType.filter(item => item.parent_id !== null);
    },

    skusByTypeTree() {
      return this.$store.getters["CUSTOMIZE_FOLDER/skusByTypeTree"];
    },

    mergedSkus() {
      const formated = this.skusByType;

      return [...formated, ...this.skus];
    }
  },

  methods: {
    getSkusById(id) {
      const findedItem = this.mergedSkus.filter(item => id.includes(item.id));
      return findedItem;
    },

    handleChangeSelectedAlll(value) {
      this.internalSkusId = value
        ? this.skusByTypeRemovedParent.map(item => item.id)
        : [];
    }
  },

  watch: {
    value(value) {
      this.isSelectedAll = value.length === this.skusByTypeRemovedParent.length;
    }
  }
};
</script>

<style scoped>
.checkbox-all {
  margin-left: 38px;
  margin-bottom: 10px;
}

.checkbox-label {
  font-size: 16px;
}
</style>
