<template>
  <div>
    <div class="font-weight-bold mb-2">{{ title }}</div>

    <div v-for="(item, index) in internalValue" :key="index">
      <div class="d-flex align-center">
        <v-checkbox
          v-model="item.available"
          :label="LIST_MODEL_SITES[item.site].text"
          :name="`${modelType}-${modelId}-${index}`"
          class="mt-0"
          dense
          hide-details
          @change="onChangeValue"
        >
        </v-checkbox>

        <div v-if="item.available && modelId" class="ml-2 d-flex">
          <v-btn
            :href="getUrlByModelSiteId(item.site)"
            color="primary"
            target="_blank"
            text
            x-small
          >
            Truy cập
          </v-btn>

          <v-btn
            class="ml-1"
            color="primary"
            text
            x-small
            @click="
              $modal.show({
                name: 'modal-utm-builder',
                payload: getUrlByModelSiteId(item.site)
              })
            "
          >
            UTM Builder
          </v-btn>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { LIST_MODEL_SITES } from "@/core/constant";

export default {
  props: {
    modelId: {
      type: Number,
      default: null
    },
    modelType: {
      type: String,
      required: true
    },
    customizeFolder: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: "Hiển thị trên website"
    },
    value: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      LIST_MODEL_SITES,
      thinkproFrontStoreUrl: process.env.VUE_APP_THINKPRO_FRONT_STORE,
      goodspaceFrontStoreUrl: process.env.VUE_APP_GOODSPACE_FRONT_STORE
    };
  },

  computed: {
    internalValue() {
      const listModelSites = Object.values(LIST_MODEL_SITES);

      if (this.value.length === listModelSites.length) return this.value;

      return listModelSites.map(item => {
        return {
          available: false,
          site: item.id,
          model_id: this.modelId,
          model_type: this.modelType
        };
      });
    }
  },

  methods: {
    getUrlByModelSiteId(modelSiteId) {
      if (modelSiteId === this.LIST_MODEL_SITES.THINKPRO.id) {
        return `${this.thinkproFrontStoreUrl}/${this.customizeFolder.shared_url}-fd`;
      }

      if (modelSiteId === this.LIST_MODEL_SITES.GOODSPACE.id) {
        return `${this.goodspaceFrontStoreUrl}/${this.customizeFolder.shared_url}-fd`;
      }
    },

    onChangeValue() {
      this.$emit("input", this.internalValue);
    }
  }
};
</script>

<style>
.d-flex.flex-wrap.align-center {
  column-gap: 24px;
  row-gap: 8px;
}
</style>
