<template>
  <div class="px-4">
    <div class="d-flex flex-row align-center mb-5">
      <div class="text-h5 font-weight-bold">
        {{ customizeFolder.id ? "Cập nhật folder" : "Thêm folder" }}
      </div>

      <v-spacer></v-spacer>
      <v-btn
        v-if="customizeFolder.id"
        :loading="
          customizeFolderStatusRequest.value === 'loading-deleteCustomizeFolder'
        "
        class="red lighten-4 red--text text-accent-2 rounded-lg mr-3"
        depressed
        @click="deleteCustomizeFolder"
      >
        Xóa
      </v-btn>
      <v-btn
        v-if="!customizeFolder.id"
        :loading="
          customizeFolderStatusRequest.value === 'loading-createCustomizeFolder'
        "
        class="rounded-lg"
        color="primary"
        depressed
        @click="createCustomizeFolder"
      >
        Thêm
      </v-btn>
      <v-btn
        v-else
        :loading="
          customizeFolderStatusRequest.value === 'loading-updateCustomizeFolder'
        "
        class="rounded-lg"
        color="primary"
        depressed
        @click="updateCustomizeFolder"
      >
        Lưu
      </v-btn>
    </div>

    <v-form ref="customizeFolderForm" v-model="customizeFolderFormValid">
      <v-row>
        <v-col class="scrollable overflow-auto py-0" cols="12" lg="4" md="5">
          <col-folder-info
            :modelSiteConfigs.sync="modelSiteConfigs"
          ></col-folder-info>
        </v-col>

        <v-col class="scrollable overflow-auto py-0" cols="12" lg="8" md="7">
          <col-folder-main></col-folder-main>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ColFolderInfo from "./components/ColFolderInfo";
import ColFolderMain from "./components/ColFolderMain";
import { showModalAlertError } from "@/core/composables";
import { LIST_MODEL_TYPES } from "@/core/constant";
import { toFormData } from "@vt7/utils";

export default {
  components: { ColFolderInfo, ColFolderMain },

  data() {
    return {
      customizeFolderFormValid: true,
      modelSiteConfigs: []
    };
  },

  computed: {
    customizeFolder() {
      return this.$store.getters["CUSTOMIZE_FOLDER/customizeFolder"];
    },

    customizeFolderStatusRequest() {
      return this.$store.getters["CUSTOMIZE_FOLDER/statusRequest"];
    },

    disabledAction() {
      return (
        !this.customizeFolder.filters ||
        Object.keys(this.customizeFolder.filters).length === 0 ||
        !this.customizeFolder.condition_filters ||
        Object.keys(this.customizeFolder.condition_filters).length === 0
      );
    },

    filtersList() {
      return this.$store.getters["CUSTOMIZE_FOLDER/filtersList"];
    },

    filtersListProperties() {
      return this.$store.getters["CUSTOMIZE_FOLDER/filtersListProperties"];
    },

    uploadedCover() {
      return this.$store.getters["CUSTOMIZE_FOLDER/uploadedCover"];
    }
  },

  async created() {
    const route = this.$route;

    if (route.params.customizeFolderId) {
      await this.$store.dispatch(
        "CUSTOMIZE_FOLDER/getCustomizeFolderById",
        route.params.customizeFolderId
      );
    }

    this.getModalSiteConfigs();
  },

  methods: {
    async getModalSiteConfigs() {
      const res = await this.$store.dispatch(
        "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
        {
          modelId: this.customizeFolder.id,
          modelType: LIST_MODEL_TYPES.FOLDER
        }
      );

      this.modelSiteConfigs = res || [];
    },

    async createCustomizeFolder() {
      if (
        this.customizeFolderStatusRequest.value ===
        "loading-createCustomizeFolder"
      )
        return;

      // Validate
      await this.$refs.customizeFolderForm.validate();
      if (!this.customizeFolderFormValid) {
        showModalAlertError(this, {
          title: "Dữ liệu đầu vào không hợp lệ",
          message:
            "Vui lòng kiểm tra lại các trường thông tin đã nhập và thử lại."
        });

        return;
      }

      // Validate modelSite
      if (!this.modelSiteConfigs.filter(item => !item.available).length) {
        showModalAlertError(this, {
          title: "Dữ liệu đầu vào không hợp lệ",
          message: "Không lòng chọn Website hiển thị"
        });

        return;
      }

      // Upload cover
      await handleUploadCover(this);

      // Check thumbnail change
      await handleUploadThumbnail(this);

      await this.$store.dispatch("CUSTOMIZE_FOLDER/createCustomizeFolder", {
        ...this.customizeFolder
      });

      if (
        this.customizeFolderStatusRequest.value ===
        "success-createCustomizeFolder"
      ) {
        await this.updateModalSiteConfigs();

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm mới folder thành công"
          }
        });

        await this.$router.push({
          name: "website_customize-folder-detail",
          params: {
            customizeFolderId: this.customizeFolder.id
          }
        });
      } else if (
        this.customizeFolderStatusRequest.value ===
        "error-createCustomizeFolder"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.customizeFolderStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async deleteCustomizeFolder() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa folder <strong>"${this.customizeFolder.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch(
                "CUSTOMIZE_FOLDER/deleteCustomizeFolder",
                {
                  customizeFolderId: this.customizeFolder.id,
                  routeQuery: {
                    search: routeQuery.search,
                    per_page: parseInt(routeQuery.limit),
                    cur_page: parseInt(routeQuery.page)
                  }
                }
              );
              if (
                this.customizeFolderStatusRequest.value ===
                "success-deleteCustomizeFolder"
              ) {
                await this.$store.dispatch(
                  "CUSTOMIZE_FOLDER/resetCustomizeFolder"
                );

                await this.$router.push({
                  name: "website_customize-folder",
                  query: {
                    search: routeQuery.search,
                    per_page: parseInt(routeQuery.limit),
                    cur_page: parseInt(routeQuery.page)
                  }
                });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              }
            }
          }
        }
      });
    },

    async updateCustomizeFolder() {
      if (
        this.customizeFolderStatusRequest.value ===
        "loading-updateCustomizeFolder"
      )
        return;

      // Validate
      await this.$refs.customizeFolderForm.validate();

      if (!this.customizeFolderFormValid) {
        showModalAlertError(this, {
          title: "Dữ liệu đầu vào không hợp lệ",
          message:
            "Vui lòng kiểm tra lại các trường thông tin đã nhập và thử lại."
        });

        return;
      }

      // Validate modelSite
      if (!this.modelSiteConfigs.filter(item => item.available).length) {
        showModalAlertError(this, {
          title: "Dữ liệu đầu vào không hợp lệ",
          message: "Vui lòng chọn Website hiển thị"
        });

        return;
      }

      // Check cover change
      await handleUploadCover(this);

      // Check thumbnail change
      await handleUploadThumbnail(this);

      await this.$store.dispatch("CUSTOMIZE_FOLDER/updateCustomizeFolder", {
        ...this.customizeFolder
      });

      if (
        this.customizeFolderStatusRequest.value ===
        "success-updateCustomizeFolder"
      ) {
        await this.updateModalSiteConfigs();

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật folder thành công"
          }
        });
      } else if (
        this.customizeFolderStatusRequest.value ===
        "error-updateCustomizeFolder"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.customizeFolderStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async updateModalSiteConfigs() {
      if (!this.modelSiteConfigs?.filter(item => item.model_id).length) {
        this.modelSiteConfigs = this.modelSiteConfigs.map(item => ({
          ...item,
          model_id: this.customizeFolder.id
        }));
      }

      await this.$store.dispatch("MODEL_SITE_CONFIG/updateModelSiteConfigs", {
        data: this.modelSiteConfigs,
        modelId: this.customizeFolder.id,
        modelType: LIST_MODEL_TYPES.FOLDER
      });
    },

    uploadCover() {
      return this.$store.dispatch(
        "CUSTOMIZE_FOLDER/uploadCover",
        toFormData({
          file: this.customizeFolder.cover
        })
      );
    },

    uploadThumbnail() {
      return this.$store.dispatch(
        "CUSTOMIZE_FOLDER/uploadThumbnail",
        toFormData({
          file: this.customizeFolder.thumbnail
        })
      );
    }
  }
};

const handleUploadCover = ctx => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (ctx.customizeFolder.cover instanceof File) {
      await ctx.uploadCover();
      // Set uploaded cover
      if (ctx.customizeFolderStatusRequest.value === "success-uploadCover") {
        ctx.customizeFolder.cover = ctx.uploadedCover;
      } else if (
        ctx.customizeFolderStatusRequest.value === "error-uploadCover"
      ) {
        showModalAlertError(ctx, {
          title: "Lỗi tải lên ảnh bìa desktop",
          message: ctx.customizeFolderStatusRequest.message
        });

        reject(new Error("Error upload desktop cover"));
      }
    }

    resolve();
  });
};

const handleUploadThumbnail = ctx => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (ctx.customizeFolder.thumbnail instanceof File) {
      await ctx.uploadThumbnail();
      // Set uploaded cover
      if (
        ctx.customizeFolderStatusRequest.value === "success-uploadThumbnail"
      ) {
        ctx.customizeFolder.thumbnail = ctx.uploadedCover;
      } else if (
        ctx.customizeFolderStatusRequest.value === "error-uploadThumbnail"
      ) {
        showModalAlertError(ctx, {
          title: "Lỗi tải lên ảnh bìa thumbnail",
          message: ctx.customizeFolderStatusRequest.message
        });

        reject(new Error("Error upload thumbnail"));
      }
    }

    resolve();
  });
};
</script>

<style lang="scss" scoped>
.scrollable {
  height: calc(100vh - 64px - 53px - 56px - 24px);
  @media only screen and (max-width: 960px) {
    height: calc(100vh - 56px - 53px - 56px - 24px);
  }
}
</style>
