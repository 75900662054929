<template>
  <div class="tp-empty-wrapper" v-bind="$attrs">
    <v-icon class="tp-empty-icon" size="100"
      >mdi-text-box-search-outline</v-icon
    >

    <div>{{ description }}</div>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: "Danh sách trống"
    }
  }
};
</script>

<style scoped>
.tp-empty-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.tp-empty-icon {
  color: inherit;
}
</style>
