<template>
  <div>
    <div>
      <div class="font-weight-bold mb-2">Tiêu đề trang</div>
      <tp-text-field
        v-model="value.title"
        :counter="120"
        :disabled="disabled"
        placeholder="Nhập tiêu đề trang"
        validate="max:120"
      ></tp-text-field>
    </div>

    <div>
      <div class="font-weight-bold mb-2">Mô tả trang</div>
      <tp-textarea
        v-model="value.description"
        :counter="320"
        :disabled="disabled"
        maxlength="320"
        placeholder="Nhập mô tả trang"
        validate="max:320"
      ></tp-textarea>
    </div>

    <div>
      <div class="font-weight-bold mb-2">Từ khóa sản phẩm</div>
      <tp-combobox
        v-model="value.tags"
        :counter="10"
        :disabled="disabled"
        deletable-chips
        multiple
        placeholder="Nhập từ khóa sản phẩm"
        small-chips
        validate="max:10"
      >
      </tp-combobox>
    </div>

    <div v-if="schedule">
      <div>
        <span>Bắt đầu</span>

        <input-date-time v-model="value.start_time"></input-date-time>
      </div>

      <div class="mt-3">
        <span>Kết thúc</span>

        <input-date-time v-model="value.end_time"></input-date-time>
      </div>
    </div>
  </div>
</template>

<script>
import InputDateTime from "../input-date-time.vue";

export default {
  components: { InputDateTime },
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    schedule: { type: Boolean, default: false }
  }
};
</script>
