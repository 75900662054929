<template>
  <div>
    <div class="mb-2 d-flex justify-space-between">
      <div class="mt-2 font-weight-bold">
        SKU đã chọn({{ listSkusSelected.length }})
      </div>

      <v-btn
        v-if="listSkusSelected.length"
        text
        color="red"
        @click="handleClearSelectedProduct"
        >Xóa hết</v-btn
      >
    </div>

    <div class="list-product-wrapper">
      <div v-for="item in listSkusSelected" :key="item.id">
        <div class="sku-wrapper d-flex justify-space-between">
          <div class="sku-infor">
            <div class="mb-1 font-weight-bold">
              {{ item.name }}
            </div>

            <div>
              {{ item.description }}
            </div>

            <div>SKU: {{ item.SKU }}</div>
          </div>

          <div class="sku-action">
            <v-btn color="red" icon @click="handleRemoveSku(item.id)"
              ><v-icon>mdi-delete-outline</v-icon></v-btn
            >
          </div>
        </div>
        <v-divider class="my-2"></v-divider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    tempSelectedSkus: {
      type: Array
    }
  },

  computed: {
    internalSelectedProductId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    listSkusSelected() {
      const data = this.uniqueData(
        [...this.tempSelectedSkus, ...this.value],
        "id"
      );
      return data;
    },

    skusByType() {
      return this.$store.getters["CUSTOMIZE_FOLDER/skusByType"];
    }
  },

  methods: {
    getSkubyId(id) {
      return this.skusByType.find(item => item.id === id);
    },

    uniqueData(array, key) {
      return array.filter(
        (obj, index, self) => index === self.findIndex(o => o[key] === obj[key])
      );
    },

    handleRemoveSku(id) {
      if (this.internalSelectedProductId.length) {
        const newSelectedProductId = this.internalSelectedProductId.filter(
          item => item.id !== id
        );
        this.$emit("input", newSelectedProductId);
      }

      if (this.tempSelectedSkus.length) {
        const newTempSelectedProductId = this.tempSelectedSkus.filter(
          item => item.id !== id
        );
        this.$emit("update:tempSelectedSkus", newTempSelectedProductId);
      }
    },

    handleClearSelectedProduct() {
      this.$emit("input", []);
      this.$emit("update:tempSelectedSkus", []);
    }
  }
};
</script>

<style scoped>
.list-product-wrapper {
  overflow: auto;
  height: 608px;
}
</style>
