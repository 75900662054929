<template>
  <div>
    <v-btn color="primary" outlined @click="isVisible = true"
      ><v-icon left>mdi-plus</v-icon>Thêm sản phẩm</v-btn
    >

    <v-dialog v-model="isVisible" width="900">
      <v-card flat>
        <v-card-title class="pb-4">
          <div class="font-weight-bold">Chọn sản phẩm</div>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="primary"
            outlined
            @click="isVisible = false"
          >
            Hủy bỏ
          </v-btn>

          <v-btn color="primary" depressed @click="handleSubmitSelectedProduct">
            Chọn
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card class="pa-4 d-flex" flat>
          <div style="width: 390px">
            <search-section
              :is-clear-search="isVisible"
              @onSearch="handleSavedTempSkus"
            ></search-section>

            <div class="relative">
              <v-overlay
                class="rounded"
                :value="
                  customizeFolderStatusRequest.value === 'loading-getSkusByType'
                "
                absolute
                opacity="0.3"
              >
                <v-progress-circular
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>

              <div class="mt-3 list-product-wrapper">
                <list-tree-product
                  v-model="selectedSkus"
                  :temp-selected-skus="tempSelectedSkus"
                ></list-tree-product>
              </div>

              <tp-empty v-if="!skusByType.length" class="grey--text"></tp-empty>
            </div>
          </div>

          <v-divider class="mx-4" vertical></v-divider>

          <div class="flex-1">
            <list-select-sku
              v-model="selectedSkus"
              :temp-selected-skus.sync="tempSelectedSkus"
            ></list-select-sku>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ListTreeProduct from "./components/ListTreeProduct.vue";
import SearchSection from "./components/SearchSection.vue";
import ListSelectSku from "./components/ListSelectSku.vue";
import TpEmpty from "./components/TpEmpty.vue";

export default {
  components: {
    ListTreeProduct,
    ListSelectSku,
    SearchSection,
    TpEmpty
  },

  props: {
    defaultValue: {
      type: Array,
      default: () => [],
      require: true
    }
  },
  data() {
    return {
      selectedSkus: [],
      isVisible: false,
      tempSelectedSkus: []
    };
  },

  computed: {
    skusByType() {
      return this.$store.getters["CUSTOMIZE_FOLDER/skusByTypeTree"];
    },

    customizeFolderStatusRequest() {
      return this.$store.getters["CUSTOMIZE_FOLDER/statusRequest"];
    }
  },

  methods: {
    handleSavedTempSkus() {
      if (!this.selectedSkus.length) return;
      this.tempSelectedSkus = [...this.tempSelectedSkus, ...this.selectedSkus];
    },

    handleSubmitSelectedProduct() {
      const submitData = this.uniqueData(
        [...this.tempSelectedSkus, ...this.selectedSkus],
        "id"
      );

      this.$emit("onSelect", submitData);

      this.isVisible = false;
    },

    uniqueData(array, key) {
      return array.filter(
        (obj, index, self) => index === self.findIndex(o => o[key] === obj[key])
      );
    }
  },
  watch: {
    isVisible(value) {
      if (value) {
        this.selectedSkus = this.defaultValue;
        this.tempSelectedSkus = this.defaultValue;
      }
    }
  }
};
</script>

<style scoped>
.list-product-wrapper {
  height: 600px;
  overflow: auto;
}

.relative {
  position: relative;
}
</style>
